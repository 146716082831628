// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".inner-circle {\n  width: 46px;\n  height: 46px;\n  border-radius: 50%;\n  border-style: solid;\n  border-width: 2px;\n  line-height: 44px;\n  border-color: #0099dc;\n  background-color: #ebf2ff;\n  color: #0099dc;\n  font-size: 14px;\n}\n.course-label {\n  padding-top: 6px;\n  height: 30px;\n  text-align: center;\n  background: rgba(0, 153, 220, 0.1019607843) 0% 0% no-repeat padding-box;\n  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1019607843);\n  border-radius: 5px;\n  opacity: 1;\n}\n.batch-label {\n  padding-top: 6px;\n  height: 30px;\n  text-align: center;\n  justify-content: center;\n  background: rgba(252, 98, 34, 0.1019607843) 0% 0% no-repeat padding-box;\n  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1019607843);\n  border-radius: 5px;\n  opacity: 1;\n}\n.announcement-details-card {\n  max-width: 760px;\n  min-height: 240px;\n  background: #ffffff 0% 0% no-repeat padding-box;\n  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1019607843);\n  border: 1px solid #d4d4d4;\n  border-radius: 10px;\n  opacity: 1;\n}\n.v-tab--active::before {\n  color: transparent;\n}\n.v-slide-group__prev {\n  display: none !important;\n}\n.v-slide-group__next {\n  display: none !important;\n}", ""]);
// Exports
exports.locals = {
	"primary": "#0099dc",
	"orange": "#FC6222",
	"decline": "#ff4848"
};
module.exports = exports;
